.react-autosuggest__container {
	position: relative;
}
.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	width: auto;
	border: 1px solid #ced4da;
	background-color: #fff;
	background-clip: padding-box;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 0.2em 0.5em;
	text-align: justify;
	text-justify: inter-word;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #000;
	color: #fff;
}
