@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&family=Roboto&display=swap');

body {
	font-family: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a.navbar-brand {
	font-family: 'Miriam Libre', Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* custom bootstrap */

.vw-50 {
	width: 50vw !important;
}
