.text-length {
    float: right;
    --bs-text-opacity: 1;
    color: #6c757d !important;
    margin-top: 0.25rem;
    font-size: 0.875em;
}

.ql-editor {
    font-size: 16px !important;
}