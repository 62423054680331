@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,700);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&family=Roboto&display=swap);
* {
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
}

#notfound {
	position: relative;
	height: 100vh;
}

#notfound .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 520px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
}

.notfound .notfound-404 {
	position: relative;
	height: 200px;
	margin: 0px auto 20px;
	z-index: -1;
}

.notfound .notfound-404 h1 {
	font-family: 'Montserrat', sans-serif;
	font-size: 236px;
	font-weight: 200;
	margin: 0px;
	color: #211b19;
	text-transform: uppercase;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
	font-family: 'Montserrat', sans-serif;
	font-size: 28px;
	font-weight: 400;
	text-transform: uppercase;
	color: #211b19;
	background: #fff;
	padding: 10px 5px;
	margin: auto;
	display: inline-block;
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
}

.notfound a {
	font-family: 'Montserrat', sans-serif;
	display: inline-block;
	font-weight: 700;
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;
	padding: 13px 23px;
	background: #024c9b;
	font-size: 18px;
	transition: 0.2s all;
}

.notfound a:hover {
	color: #024c9b;
	background: #211b19;
}

@media only screen and (max-width: 767px) {
	.notfound .notfound-404 h1 {
		font-size: 148px;
	}
}

@media only screen and (max-width: 480px) {
	.notfound .notfound-404 {
		height: 148px;
		margin: 0px auto 10px;
	}
	.notfound .notfound-404 h1 {
		font-size: 86px;
	}
	.notfound .notfound-404 h2 {
		font-size: 16px;
	}
	.notfound a {
		padding: 7px 15px;
		font-size: 14px;
	}
}

.react-autosuggest__container {
	position: relative;
}
.react-autosuggest__suggestions-container {
	display: none;
}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	width: auto;
	border: 1px solid #ced4da;
	background-color: #fff;
	background-clip: padding-box;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 0.2em 0.5em;
	text-align: justify;
	text-justify: inter-word;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #000;
	color: #fff;
}

.my-modal {
    width: 70vw;
    max-width: 70vw;
}
.text-length {
    float: right;
    --bs-text-opacity: 1;
    color: #6c757d !important;
    margin-top: 0.25rem;
    font-size: 0.875em;
}

.ql-editor {
    font-size: 16px !important;
}
.my-modal {
    width: 65vw;
    max-width: 65vw;
} 
.custom-select {
    position: relative;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.selected {
    padding: 10px;
}

.selected.disabled {
    background: #eee;
    cursor: not-allowed;
}

.options {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.options li {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    white-space: nowrap; 
}

.options li:hover {
    background: #f0f0f0;
}

.star-icon::before {
    content: "⭐ ";
    color: gold;
}

.generateCVButton {
	background-color: #ffd514;
	border-color: #ffd514;
}

.navButton {
    background-color: #ffd514;
    border-color: #ffd514;
}
a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}

.box-shadow {
	box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

#version {
	position: fixed;
	right: 1em;
	bottom: 0;
}

.layout-container {
	display: flex;
	flex-direction: column;
	min-height: 84vh;
	max-width: 85%;
}

.main {
	flex: 1 1;
}
.forgotPwd {
	float: right;
	font-size: 80%;
	position: relative;
	top: 5px;
}

.forgotPwd > a {
	color: #337ab7;
	text-decoration: none;
}

.forgotPwd > a:focus,
.forgotPwd > a:hover {
	color: #23527c;
	text-decoration: underline;
}

.forgotPwd > a:active,
.forgotPwd > a:hover {
	outline: 0;
}

.panel-header {
	color: #fff;
	background-color: #000;
	border-color: #d3d3d3;
}

.btn-login {
	color: #000;
	background-color: #c0c0c0;
	border-color: #c0c0c0;
}

.btn-login:hover {
	color: #000;
	background-color: #abab9a;
	border-color: #abab9a;
	box-shadow: none !important;
	outline: 0;
}

.btn-login:active,
.btn-login:focus {
	background-color: #abab9a !important;
	box-shadow: none !important;
	outline: 0;
}

.btn-login:focus-visible {
	color: #000;
	background-color: #c0c0c0;
	border-color: #c0c0c0;
}

body {
	font-family: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a.navbar-brand {
	font-family: 'Miriam Libre', Helvetica Neue, Helvetica, Arial, sans-serif;
}

/* custom bootstrap */

.vw-50 {
	width: 50vw !important;
}

