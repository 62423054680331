.forgotPwd {
	float: right;
	font-size: 80%;
	position: relative;
	top: 5px;
}

.forgotPwd > a {
	color: #337ab7;
	text-decoration: none;
}

.forgotPwd > a:focus,
.forgotPwd > a:hover {
	color: #23527c;
	text-decoration: underline;
}

.forgotPwd > a:active,
.forgotPwd > a:hover {
	outline: 0;
}

.panel-header {
	color: #fff;
	background-color: #000;
	border-color: #d3d3d3;
}

.btn-login {
	color: #000;
	background-color: #c0c0c0;
	border-color: #c0c0c0;
}

.btn-login:hover {
	color: #000;
	background-color: #abab9a;
	border-color: #abab9a;
	box-shadow: none !important;
	outline: 0;
}

.btn-login:active,
.btn-login:focus {
	background-color: #abab9a !important;
	box-shadow: none !important;
	outline: 0;
}

.btn-login:focus-visible {
	color: #000;
	background-color: #c0c0c0;
	border-color: #c0c0c0;
}
