.custom-select {
    position: relative;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    user-select: none;
}

.selected {
    padding: 10px;
}

.selected.disabled {
    background: #eee;
    cursor: not-allowed;
}

.options {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.options li {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    white-space: nowrap; 
}

.options li:hover {
    background: #f0f0f0;
}

.star-icon::before {
    content: "⭐ ";
    color: gold;
}
